const dealersData=[
    {"dealerId":"OH082","dealerName":"Victory Hyundai"},
    {"dealerId":"WI032","dealerName":"Bergstrom Hyundai"},
    {"dealerId":"WI025","dealerName":"Dahl Hyundai"},
    {"dealerId":"OH022","dealerName":"Tom Ahl Hyundai"},
    {"dealerId":"IL028","dealerName":"Carmack Hyundai"},
    {"dealerId":"MA046","dealerName":"Route 44 Hyundai"},
    {"dealerId":"NY122","dealerName":"Simmons Rockwell Hyundai"},
    {"dealerId":"VA050","dealerName":"Wright Way Hyundai"},
    {"dealerId":"WV005","dealerName":"Dutch Miller Hyundai"},
    {"dealerId":"NC067","dealerName":"Classic Hyundai of Wilkesboro"},
    {"dealerId":"VA030","dealerName":"Duncan Hyundai"},
    {"dealerId":"NC046","dealerName":"Pinehurst Hyundai"},
    {"dealerId":"CO049","dealerName":"Red Rock Hyundai"},
    {"dealerId":"CO053","dealerName":"Arapahoe Hyundai"},
    {"dealerId":"CO054","dealerName":"Stevinson Hyundai of Longmont"},
    {"dealerId":"WY005","dealerName":"Ken Garff Hyundai Cheyenne"},
    {"dealerId":"AZ053","dealerName":"Larry H. Miller Hyundai Peoria"},
    {"dealerId":"AZ022","dealerName":"Lawley Hyundai"},
    {"dealerId":"NM023","dealerName":"Fiesta Hyundai"},
    {"dealerId":"NM024","dealerName":"Larry H. Miller Sw Hyundai Albuquer"},
    {"dealerId":"NM025","dealerName":"Larry H. Miller Hyundai Albuquerque"},
    {"dealerId":"NE009","dealerName":"Tom Dinsdale Hyundai"},
    {"dealerId":"AR037","dealerName":"Orr Hyundai of Russellville"},
    {"dealerId":"TX194","dealerName":"Foundation Hyundai of Wichita Falls"},
    {"dealerId":"TX117","dealerName":"Mac Haik Hyundai"},
    {"dealerId":"KS014","dealerName":"G&G Hyundai"},
    {"dealerId":"LA037","dealerName":"Giles Hyundai"},
    {"dealerId":"AL032","dealerName":"Hyundai of Jasper"},
    {"dealerId":"TN034","dealerName":"Gray Epperson Hyundai"},
    {"dealerId":"GA003","dealerName":"Ed Voyles Hyundai"},
    {"dealerId":"GA070","dealerName":"Nalley Hyundai"},
    {"dealerId":"SC048","dealerName":"ALM Hyundai Florence"},
    {"dealerId":"AL027","dealerName":"Eastern Shore Hyundai"},
    {"dealerId":"FL081","dealerName":"Courtesy Hyundai"},
    {"dealerId":"FL138","dealerName":"Coggin Deland Hyundai"},
    {"dealerId":"CA01Q","dealerName":"Hanford Hyundai"},
    {"dealerId":"CA332","dealerName":"Merced Hyundai"},
    {"dealerId":"HI010","dealerName":"Kuhio Hyundai"},
    {"dealerId":"IL003","dealerName":"Napleton Hyundai of Glenview"},
    {"dealerId":"IL062","dealerName":"Hyundai On Perryville"},
    {"dealerId":"MD028","dealerName":"Antwerpen Hyundai"},
    {"dealerId":"MD006","dealerName":"Pohanka Hyundai"},
    {"dealerId":"VA014","dealerName":"Pohanka Hyundai of Fredericksburg"},
    {"dealerId":"TX077","dealerName":"World Car Hyundai"},
    {"dealerId":"TX087","dealerName":"World Car Hyundai"},
    {"dealerId":"FL149","dealerName":"Hyundai of Wesley Chapel"},
    {"dealerId":"OR036","dealerName":"Team Hyundai of Bend"},
    {"dealerId":"IL095","dealerName":"Happy Hyundai of Libertyville"},
    {"dealerId":"IL081","dealerName":"Happy Hyundai"},
    {"dealerId":"IN049","dealerName":"Bachman Hyundai"},
    {"dealerId":"MN020","dealerName":"Walser Hyundai"},
    {"dealerId":"IA016","dealerName":"Stew Hansen Hyundai"},
    {"dealerId":"IL050","dealerName":"Bob Brady Hyundai"},
    {"dealerId":"NH015","dealerName":"Somersworth Hyundai"},
    {"dealerId":"MA040","dealerName":"Pride Hyundai of Lynn"},
    {"dealerId":"CT018","dealerName":"Meriden Hyundai"},
    {"dealerId":"NY136","dealerName":"Centereach Hyundai"},
    {"dealerId":"VA031","dealerName":"Robert Woodall Hyundai"},
    {"dealerId":"AZ052","dealerName":"Ken Garff Hyundai Surprise"},
    {"dealerId":"UT002","dealerName":"Ken Garff Hyundai Downtown"},
    {"dealerId":"MT007","dealerName":"Underriner Hyundai"},
    {"dealerId":"ND006","dealerName":"Gateway Hyundai"},
    {"dealerId":"LA036","dealerName":"Ray Brandt Hyundai of Harvey Llc"},
    {"dealerId":"TX192","dealerName":"Big Star Hyundai"},
    {"dealerId":"TX148","dealerName":"Bert Ogden Harlingen Hyundai"},
    {"dealerId":"AL020","dealerName":"Bentley Hyundai"},
    {"dealerId":"AL030","dealerName":"Tameron Hyundai"},
    {"dealerId":"AL022","dealerName":"Team One Hyundai of Gadsden"},
    {"dealerId":"SC043","dealerName":"Benson Hyundai"},
    {"dealerId":"FL131","dealerName":"Kendall Hyundai"},
    {"dealerId":"WA045","dealerName":"Rairdons Hyundai of Bellingham"},
    {"dealerId":"NV014","dealerName":"Lithia Hyundai of Reno"},
    {"dealerId":"AL034","dealerName":"Serra Hyundai"},
    {"dealerId":"CA01R","dealerName":"Autonation Hyundai Valencia"},
    {"dealerId":"CT029","dealerName":"Key Hyundai of Manchester"},
    {"dealerId":"NC032","dealerName":"Bob King Hyundai"},
    {"dealerId":"CT033","dealerName":"Key Hyundai of Milford"},
    {"dealerId":"NY005","dealerName":"Huntington Hyundai, Inc."},
    {"dealerId":"IL073","dealerName":"Mcgrath City Hyundai"},
    {"dealerId":"AR031","dealerName":"Smart Hyundai"},
    {"dealerId":"CA383","dealerName":"San Leandro Hyundai"},
    {"dealerId":"AZ044","dealerName":"Oxendale Hyundai"},
    {"dealerId":"NY025","dealerName":"Suresky Hyundai"},
    {"dealerId":"TX176","dealerName":"Greg May Hyundai"},
    {"dealerId":"IL072","dealerName":"World Hyundai Matteson"},
    {"dealerId":"IL091","dealerName":"Phillips Hyundai of Bradley"},
    {"dealerId":"IN044","dealerName":"Andy Mohr Hyundai"},
    {"dealerId":"WI040","dealerName":"Stevens Point Hyundai"},
    {"dealerId":"MN016","dealerName":"Luther Burnsville Hyundai"},
    {"dealerId":"MN021","dealerName":"Luther Bloomington Hyundai"},
    {"dealerId":"MI053","dealerName":"Betten Baker Hyundai"},
    {"dealerId":"IN042","dealerName":"Dorsett's Hyundai"},
    {"dealerId":"MI007","dealerName":"Glassman Hyundai"},
    {"dealerId":"NH014","dealerName":"Key Hyundai of Salem"},
    {"dealerId":"CT040","dealerName":"Wile Hyundai"},
    {"dealerId":"MA057","dealerName":"Country Hyundai"},
    {"dealerId":"CO052","dealerName":"Foundation Hyundai of Boulder"},
    {"dealerId":"NM002","dealerName":"Borman Hyundai"},
    {"dealerId":"OK032","dealerName":"Patriot Hyundai"},
    {"dealerId":"TX118","dealerName":"Orr Hyundai"},
    {"dealerId":"TX198","dealerName":"Texoma Hyundai"},
    {"dealerId":"TX199","dealerName":"Greenville Hyundai"},
    {"dealerId":"TX191","dealerName":"Shottenkirk Hyundai Granbury"},
    {"dealerId":"MO044","dealerName":"Auffenberg Hyundai Cape Girardeau"},
    {"dealerId":"TN053","dealerName":"Allen Samuels Hyundai"},
    {"dealerId":"GA088","dealerName":"Shottenkirk Hyundai"},
    {"dealerId":"MT013","dealerName":"Missoula Hyundai"},
    {"dealerId":"CA388","dealerName":"Hyundai San Luis Obispo"},
    {"dealerId":"MA064","dealerName":"Boch Hyundai"},
    {"dealerId":"RI013","dealerName":"Nucar Tarbox Hyundai"},
    {"dealerId":"NM006","dealerName":"Horace Hyundai"},
    {"dealerId":"OR040","dealerName":"Hyundai of Albany"},
    {"dealerId":"OH062","dealerName":"Don Wood Hyundai"},
    {"dealerId":"OR041","dealerName":"Hyundai Medford"},
    {"dealerId":"TX193","dealerName":"Charlie Clark Hyundai"},
    {"dealerId":"WA052","dealerName":"Haselwood Hyundai"},
    {"dealerId":"VA036","dealerName":"Cma's Colonial Hyundai"},
    {"dealerId":"CA296","dealerName":"Downey Hyundai"},
    {"dealerId":"IL068","dealerName":"Elgin Hyundai"},
    {"dealerId":"IL069","dealerName":"Patrick Hyundai"},
    {"dealerId":"IL080","dealerName":"Gerald Hyundai"},
    {"dealerId":"IL085","dealerName":"Autonation Hyundai O'hare"},
    {"dealerId":"IL059","dealerName":"D'arcy Hyundai"},
    {"dealerId":"IL066","dealerName":"Ettleson Hyundai"},
    {"dealerId":"IL071","dealerName":"Napleton's Hyundai"},
    {"dealerId":"IL082","dealerName":"Napleton's Valley Hyundai"},
    {"dealerId":"IL097","dealerName":"Napleton Downtown Hyundai"},
    {"dealerId":"IN038","dealerName":"Webb Hyundai"},
    {"dealerId":"IN045","dealerName":"Webb Hyundai Merrillville"},
    {"dealerId":"IN028","dealerName":"Ray Skillman Southside Hyundai"},
    {"dealerId":"IN036","dealerName":"Ray Skillman Westside Hyundai"},
    {"dealerId":"IN047","dealerName":"Ray Skillman Avon Hyundai"},
    {"dealerId":"IN048","dealerName":"Napleton Hyundai of Carmel"},
    {"dealerId":"IN050","dealerName":"Greg Hubler Hyundai"},
    {"dealerId":"IN052","dealerName":"Hyundai of Columbus"},
    {"dealerId":"IN053","dealerName":"Hyundai of Noblesville"},
    {"dealerId":"KY012","dealerName":"Kerry Hyundai"},
    {"dealerId":"KY013","dealerName":"Kerry Hyundai of Florence"},
    {"dealerId":"OH018","dealerName":"Columbia Hyundai"},
    {"dealerId":"OH080","dealerName":"Jeff Wyler Hyundai of Fairfield"},
    {"dealerId":"OH081","dealerName":"Jeff Wyler Superior Hyundai"},
    {"dealerId":"IL026","dealerName":"Watermark Hyundai of Marion"},
    {"dealerId":"IN055","dealerName":"Hyundai of Evansville"},
    {"dealerId":"KY009","dealerName":"Jim Johnson Hyundai"},
    {"dealerId":"KY020","dealerName":"Linwood Hyundai"},
    {"dealerId":"KY029","dealerName":"Tim Short Hyundai"},
    {"dealerId":"KY034","dealerName":"Don Franklin Somerset Hyundai"},
    {"dealerId":"OH056","dealerName":"Graham Hyundai"},
    {"dealerId":"OH058","dealerName":"Crestmont Hyundai"},
    {"dealerId":"OH073","dealerName":"Hyundai of Bedford"},
    {"dealerId":"OH074","dealerName":"Elyria Hyundai"},
    {"dealerId":"OH076","dealerName":"Ken Ganley Hyundai Norwalk"},
    {"dealerId":"OH083","dealerName":"Ken Ganley Hyundai Parma"},
    {"dealerId":"OH084","dealerName":"Ken Ganley Hyundai North Olmsted"},
    {"dealerId":"WI021","dealerName":"Van Horn Hyundai"},
    {"dealerId":"WI023","dealerName":"Van Horn Hyundai Fond Du Lac"},
    {"dealerId":"WI026","dealerName":"Zimbrick Eastside Hyundai"},
    {"dealerId":"WI031","dealerName":"Kocourek Hyundai"},
    {"dealerId":"WI036","dealerName":"Hyundai West Allis"},
    {"dealerId":"WI038","dealerName":"Rosen Hyundai of Kenosha"},
    {"dealerId":"WI042","dealerName":"Zeigler Hyundai of Racine"},
    {"dealerId":"MN024","dealerName":"Dondelinger Hyundai"},
    {"dealerId":"MN028","dealerName":"St. Cloud Hyundai"},
    {"dealerId":"OH045","dealerName":"Voss Hyundai"},
    {"dealerId":"OH048","dealerName":"Jeff Wyler Hyundai"},
    {"dealerId":"OH068","dealerName":"Coughlin Hyundai"},
    {"dealerId":"OH071","dealerName":"Joseph Airport Hyundai"},
    {"dealerId":"OH079","dealerName":"Jeff Wyler Hyundai of Beavercreek"},
    {"dealerId":"IA010","dealerName":"C&S Hyundai"},
    {"dealerId":"IA013","dealerName":"Smart Hyundai of Davenport"},
    {"dealerId":"IA015","dealerName":"Billion Hyundai"},
    {"dealerId":"IA018","dealerName":"Mcgrath Hyundai of Dubuque"},
    {"dealerId":"IA019","dealerName":"Mcgrath Hyundai of Cedar Rapids"},
    {"dealerId":"IL060","dealerName":"Schimmer Hyundai"},
    {"dealerId":"NE011","dealerName":"Woodhouse Hyundai of Omaha"},
    {"dealerId":"NE012","dealerName":"Beardmore Hyundai"},
    {"dealerId":"IN013","dealerName":"Glenbrook Hyundai"},
    {"dealerId":"IN021","dealerName":"Gurley Leep Hyundai"},
    {"dealerId":"IN051","dealerName":"Michigan City Hyundai"},
    {"dealerId":"IN054","dealerName":"Hyundai of Goshen"},
    {"dealerId":"MI028","dealerName":"Elhart Hyundai"},
    {"dealerId":"MI031","dealerName":"Fox Hyundai"},
    {"dealerId":"MI034","dealerName":"Bill Marsh Hyundai"},
    {"dealerId":"MI043","dealerName":"Fernelius Hyundai"},
    {"dealerId":"MI057","dealerName":"Signature Hyundai"},
    {"dealerId":"IL036","dealerName":"K.C. Summers Hyundai"},
    {"dealerId":"IL088","dealerName":"Napleton's Hyundai of Urbana"},
    {"dealerId":"MO011","dealerName":"Dean Team Hyundai"},
    {"dealerId":"MO046","dealerName":"Napleton Hyundai"},
    {"dealerId":"MO048","dealerName":"Clement Hyundai"},
    {"dealerId":"MI027","dealerName":"Lafontaine Hyundai"},
    {"dealerId":"MI058","dealerName":"Fox Ann Arbor Hyundai"},
    {"dealerId":"MI061","dealerName":"Lafontaine Hyundai of Livonia"},
    {"dealerId":"ME004","dealerName":"Rowe Hyundai"},
    {"dealerId":"ME005","dealerName":"Rowe Hyundai Westbrook"},
    {"dealerId":"ME008","dealerName":"Bill Dodge Hyundai"},
    {"dealerId":"ME009","dealerName":"Darling's Hyundai Augusta"},
    {"dealerId":"NH006","dealerName":"Hampton Hyundai"},
    {"dealerId":"NH013","dealerName":"Hyundai of Keene"},
    {"dealerId":"NH018","dealerName":"Autofair Hyundai"},
    {"dealerId":"MA021","dealerName":"Herb Chambers Hyundai"},
    {"dealerId":"MA024","dealerName":"Mirak Hyundai"},
    {"dealerId":"MA063","dealerName":"Mcgovern Hyundai"},
    {"dealerId":"MA065","dealerName":"Imperial Hyundai"},
    {"dealerId":"MA067","dealerName":"Mcgovern Hyundai Route 2"},
    {"dealerId":"MA069","dealerName":"Mcgovern Hyundai Route 93"},
    {"dealerId":"CT032","dealerName":"Central Hyundai"},
    {"dealerId":"CT041","dealerName":"Shoreline Hyundai"},
    {"dealerId":"MA029","dealerName":"Pride Hyundai of Seekonk"},
    {"dealerId":"MA044","dealerName":"Empire Hyundai"},
    {"dealerId":"MA056","dealerName":"First Hyundai"},
    {"dealerId":"MA066","dealerName":"Hyundai of Plymouth"},
    {"dealerId":"RI011","dealerName":"Hyundai of Newport"},
    {"dealerId":"CT022","dealerName":"Danbury Hyundai"},
    {"dealerId":"CT034","dealerName":"Stamford Hyundai Llc"},
    {"dealerId":"CT038","dealerName":"Brandfon Hyundai"},
    {"dealerId":"CT039","dealerName":"Shaker's Family Hyundai"},
    {"dealerId":"NY060","dealerName":"Healey Hyundai"},
    {"dealerId":"NY119","dealerName":"Central Avenue Hyundai"},
    {"dealerId":"NY075","dealerName":"Atlantic Hyundai"},
    {"dealerId":"NY110","dealerName":"Advantage Hyundai"},
    {"dealerId":"NY126","dealerName":"Millennium Hyundai"},
    {"dealerId":"NY140","dealerName":"Hyundai of 110"},
    {"dealerId":"NJ007","dealerName":"Global Hyundai"},
    {"dealerId":"NJ015","dealerName":"Maxon Hyundai"},
    {"dealerId":"NJ046","dealerName":"Hudson Hyundai"},
    {"dealerId":"NJ011","dealerName":"Freehold Hyundai"},
    {"dealerId":"NJ025","dealerName":"Circle Hyundai"},
    {"dealerId":"NJ039","dealerName":"Burns Hyundai"},
    {"dealerId":"NJ040","dealerName":"Hyundai of Turnersville"},
    {"dealerId":"NJ056","dealerName":"Action Hyundai"},
    {"dealerId":"NJ060","dealerName":"Fred Beans Hyundai of Flemington"},
    {"dealerId":"NJ065","dealerName":"Route 1 Hyundai"},
    {"dealerId":"PA002","dealerName":"Fred Beans Hyundai of Langhorne"},
    {"dealerId":"PA039","dealerName":"Fred Beans Hyundai"},
    {"dealerId":"DE004","dealerName":"Porter Hyundai"},
    {"dealerId":"DE007","dealerName":"Hertrich Hyundai"},
    {"dealerId":"PA065","dealerName":"Conicelli Hyundai"},
    {"dealerId":"PA070","dealerName":"Ciocca Hyundai"},
    {"dealerId":"PA083","dealerName":"Piazza Hyundai of West Chester"},
    {"dealerId":"PA093","dealerName":"Colonial Hyundai"},
    {"dealerId":"PA014","dealerName":"Seidel Hyundai"},
    {"dealerId":"PA048","dealerName":"Jack Giambalvo Hyundai"},
    {"dealerId":"PA068","dealerName":"Lancaster Hyundai"},
    {"dealerId":"PA072","dealerName":"Stoltz Hyundai of Dubois"},
    {"dealerId":"PA075","dealerName":"Sunbury Motors Hyundai"},
    {"dealerId":"PA077","dealerName":"Blaise Alexander Hyundai"},
    {"dealerId":"PA089","dealerName":"Giambalvo Hyundai of Hanover"},
    {"dealerId":"PA095","dealerName":"Blaise Alexander Hyundai of Altoona"},
    {"dealerId":"PA096","dealerName":"Chambersburg Hyundai"},
    {"dealerId":"PA053","dealerName":"Laurel Hyundai"},
    {"dealerId":"PA057","dealerName":"Mike Kelly Hyundai"},
    {"dealerId":"PA067","dealerName":"Delaney Hyundai"},
    {"dealerId":"PA084","dealerName":"Mike Camlin Hyundai of Greensburg"},
    {"dealerId":"NY013","dealerName":"Northtown Hyundai"},
    {"dealerId":"NY024","dealerName":"Miller Hyundai"},
    {"dealerId":"NY102","dealerName":"Shults Hyundai"},
    {"dealerId":"NY123","dealerName":"Maguire Hyundai"},
    {"dealerId":"NY142","dealerName":"Matthews Hyundai of Greece"},
    {"dealerId":"NY152","dealerName":"Maguire Hyundai of Grand Island"},
    {"dealerId":"PA019","dealerName":"Dave Hallman Hyundai, Inc."},
    {"dealerId":"NJ049","dealerName":"Franklin Sussex Hyundai"},
    {"dealerId":"NY076","dealerName":"Burdick Hyundai"},
    {"dealerId":"NY141","dealerName":"Matthews Hyundai of Syracuse"},
    {"dealerId":"NY145","dealerName":"Mastrovito Hyundai"},
    {"dealerId":"PA018","dealerName":"Motorworld Hyundai"},
    {"dealerId":"PA085","dealerName":"Brown-Daub Hyundai"},
    {"dealerId":"PA086","dealerName":"Major Hyundai of Stroudsburg"},
    {"dealerId":"PA088","dealerName":"Ciocca Hyundai of Williamsport"},
    {"dealerId":"CT012","dealerName":"Torrington Hyundai"},
    {"dealerId":"MA031","dealerName":"Haddad Hyundai"},
    {"dealerId":"MA034","dealerName":"Gary Rome Hyundai"},
    {"dealerId":"NY096","dealerName":"Garvey Hyundai North"},
    {"dealerId":"NY148","dealerName":"Matthews Hyundai of Schenectady"},
    {"dealerId":"VT001","dealerName":"Burlington Hyundai"},
    {"dealerId":"VT008","dealerName":"Mcgee Hyundai of Barre"},
    {"dealerId":"MD010","dealerName":"Massey Hyundai"},
    {"dealerId":"MD021","dealerName":"Bel Air Hyundai"},
    {"dealerId":"MD040","dealerName":"Annapolis Hyundai"},
    {"dealerId":"MD042","dealerName":"Heritage Hyundai Towson"},
    {"dealerId":"MD038","dealerName":"Ourisman Hyundai"},
    {"dealerId":"VA051","dealerName":"Hyundai of Chantilly"},
    {"dealerId":"MD030","dealerName":"Team Hyundai"},
    {"dealerId":"NC062","dealerName":"Hall Hyundai Elizabeth City"},
    {"dealerId":"VA003","dealerName":"Pearson Hyundai"},
    {"dealerId":"VA024","dealerName":"West Broad Hyundai"},
    {"dealerId":"VA044","dealerName":"Hall Hyundai Chesapeake"},
    {"dealerId":"VA045","dealerName":"Priority Hyundai Greenbrier"},
    {"dealerId":"VA046","dealerName":"Hall Hyundai Newport News"},
    {"dealerId":"VA057","dealerName":"Cma's Hyundai of Winchester"},
    {"dealerId":"WV018","dealerName":"Jenkins Hyundai"},
    {"dealerId":"WV021","dealerName":"Weimer Hyundai of Morgantown, Inc."},
    {"dealerId":"NC053","dealerName":"Crenshaw Hyundai"},
    {"dealerId":"NC066","dealerName":"Capital Hyundai of Greensboro"},
    {"dealerId":"NC018","dealerName":"Joe Pecheles Hyundai"},
    {"dealerId":"NC027","dealerName":"Medlin Hyundai"},
    {"dealerId":"NC028","dealerName":"Southern States Hyundai"},
    {"dealerId":"NC069","dealerName":"Capital Hyundai of Jacksonville"},
    {"dealerId":"NC029","dealerName":"Paramount Hyundai"},
    {"dealerId":"NC038","dealerName":"Keffer Hyundai"},
    {"dealerId":"NC041","dealerName":"Keith Hawthorne Hyundai"},
    {"dealerId":"NC043","dealerName":"Paramount Hyundai of Hickory"},
    {"dealerId":"NC044","dealerName":"Lake Norman Hyundai"},
    {"dealerId":"NC064","dealerName":"South Charlotte Hyundai"},
    {"dealerId":"NC065","dealerName":"Bob Mayberry Hyundai"},
    {"dealerId":"NC071","dealerName":"Flow Hyundai of Statesville"},
    {"dealerId":"SC030","dealerName":"Fort Mill Hyundai"},
    {"dealerId":"CO012","dealerName":"Spradley Hyundai, Inc."},
    {"dealerId":"CO025","dealerName":"Phil Long Hyun of Chapel Hills"},
    {"dealerId":"CO051","dealerName":"Foundation Hyundai"},
    {"dealerId":"AZ032","dealerName":"Chapman Hyundai"},
    {"dealerId":"AZ036","dealerName":"Chapman Scottsdale Hyundai"},
    {"dealerId":"AZ046","dealerName":"Autonation Hyundai Tempe"},
    {"dealerId":"AZ020","dealerName":"High Desert Hyundai"},
    {"dealerId":"AZ034","dealerName":"Hyundai of Cottonwood"},
    {"dealerId":"AZ054","dealerName":"Findlay Hyundai Prescott"},
    {"dealerId":"ID007","dealerName":"Bronco Motors Hyundai"},
    {"dealerId":"ID016","dealerName":"Bronco Motors Hyundai West"},
    {"dealerId":"UT011","dealerName":"Findlay Hyundai"},
    {"dealerId":"AZ048","dealerName":"Hyundai of Yuma"},
    {"dealerId":"NM020","dealerName":"Hamilton Hyundai"},
    {"dealerId":"ID009","dealerName":"Rob Green Hyundai"},
    {"dealerId":"ID015","dealerName":"Woody Smith Hyundai"},
    {"dealerId":"MT014","dealerName":"Clark Hyundai"},
    {"dealerId":"ND002","dealerName":"Don Bessette Hyundai"},
    {"dealerId":"SD001","dealerName":"Billion Hyundai"},
    {"dealerId":"SD005","dealerName":"Liberty Hyundai"},
    {"dealerId":"KS009","dealerName":"Noller Hyundai"},
    {"dealerId":"KS010","dealerName":"Mccarthy Olathe Hyundai"},
    {"dealerId":"KS011","dealerName":"Laird Noller Hyundai"},
    {"dealerId":"MO006","dealerName":"Northtowne Hyundai"},
    {"dealerId":"MO029","dealerName":"Mccarthy Hyundai"},
    {"dealerId":"MO049","dealerName":"Hyundai of Jefferson City"},
    {"dealerId":"OK019","dealerName":"Billingsley Hyundai of Lawton"},
    {"dealerId":"OK028","dealerName":"Regional Hyundai"},
    {"dealerId":"OK031","dealerName":"Tulsa Hyundai"},
    {"dealerId":"AR014","dealerName":"Cavenaugh Hyundai"},
    {"dealerId":"AR026","dealerName":"Cavenaugh Hyundai"},
    {"dealerId":"AR038","dealerName":"Chris Crain Hyundai"},
    {"dealerId":"LA009","dealerName":"Navarre Hyundai"},
    {"dealerId":"LA029","dealerName":"Ralph Sellers Hyundai"},
    {"dealerId":"LA038","dealerName":"Hyundai Slidell"},
    {"dealerId":"TX103","dealerName":"Philpott Motors Hyundai"},
    {"dealerId":"LA034","dealerName":"Interstate Hyundai"},
    {"dealerId":"MS029","dealerName":"Jason Pilger Hyundai"},
    {"dealerId":"MS038","dealerName":"Barnes Crossing Hyundai"},
    {"dealerId":"MS041","dealerName":"Matt Bowers Hyundai-Gulfport"},
    {"dealerId":"MS042","dealerName":"Hallmark Hyundai Flowood"},
    {"dealerId":"MS043","dealerName":"Hallmark Hyundai North"},
    {"dealerId":"TX040","dealerName":"Huffines Hyundai Plano"},
    {"dealerId":"TX108","dealerName":"Perry Hyundai"},
    {"dealerId":"TX132","dealerName":"Huffines Hyundai Mckinney"},
    {"dealerId":"TX151","dealerName":"Hyundai of Longview"},
    {"dealerId":"TX185","dealerName":"Patterson Hyundai Tyler"},
    {"dealerId":"TX187","dealerName":"Hyundai of Lufkin"},
    {"dealerId":"TX202","dealerName":"Clay Cooley Hyundai of Dallas"},
    {"dealerId":"TX062","dealerName":"Freeman Hyundai"},
    {"dealerId":"TX136","dealerName":"Jerry's Hyundai"},
    {"dealerId":"TX141","dealerName":"James Wood Hyundai"},
    {"dealerId":"TX152","dealerName":"Amarillo Hyundai"},
    {"dealerId":"TX173","dealerName":"Vandergriff Hyundai"},
    {"dealerId":"TX174","dealerName":"Van Hyundai"},
    {"dealerId":"TX181","dealerName":"Autonation Hyundai N Richland Hills"},
    {"dealerId":"TX095","dealerName":"Round Rock Hyundai"},
    {"dealerId":"TX200","dealerName":"Steele Hyundai Kyle, Inc"},
    {"dealerId":"TX086","dealerName":"Wiesner Hyundai"},
    {"dealerId":"TX153","dealerName":"Hyundai of Brenham"},
    {"dealerId":"TX155","dealerName":"Texan Hyundai"},
    {"dealerId":"TX205","dealerName":"Brookshire Hyundai"},
    {"dealerId":"TX081","dealerName":"Autonation Hyundai Corpus Christi"},
    {"dealerId":"TX167","dealerName":"Hyundai of Pharr"},
    {"dealerId":"OK025","dealerName":"Barry Sanders Super Center"},
    {"dealerId":"TX125","dealerName":"Hyundai of Del Rio"},
    {"dealerId":"TX179","dealerName":"Car Town Hyundai"},
    {"dealerId":"TX204","dealerName":"Clay Cooley Hyundai of Terrell"},
    {"dealerId":"TN029","dealerName":"Twin City Hyundai"},
    {"dealerId":"TN033","dealerName":"Grayson Hyundai"},
    {"dealerId":"TN045","dealerName":"Wilson County Hyundai, Inc"},
    {"dealerId":"TN046","dealerName":"Hyundai of Cool Springs"},
    {"dealerId":"TN047","dealerName":"Hyundai of Cookeville"},
    {"dealerId":"TN048","dealerName":"Downtown Hyundai"},
    {"dealerId":"TN051","dealerName":"Murfreesboro Hyundai"},
    {"dealerId":"TN052","dealerName":"Hyundai of Columbia"},
    {"dealerId":"TN054","dealerName":"Tarr Hyundai"},
    {"dealerId":"TN055","dealerName":"Hallmark Hyundai"},
    {"dealerId":"TN056","dealerName":"Wyatt Johnson Hyundai"},
    {"dealerId":"AL014","dealerName":"Jim Burke Hyundai"},
    {"dealerId":"AL031","dealerName":"University Hyundai of Decatur"},
    {"dealerId":"AL033","dealerName":"Greenway Hyundai of The Shoals"},
    {"dealerId":"MS030","dealerName":"Pride Hyundai"},
    {"dealerId":"MS032","dealerName":"Homer Skelton Hyundai"},
    {"dealerId":"TN030","dealerName":"Gossett Hyundai"},
    {"dealerId":"TN031","dealerName":"Gossett Hyundai South"},
    {"dealerId":"GA065","dealerName":"Autonation Hyundai Mall of Georgia"},
    {"dealerId":"GA082","dealerName":"Mtn View Hyundai"},
    {"dealerId":"GA091","dealerName":"Shottenkirk Hyundai Rome"},
    {"dealerId":"TN008","dealerName":"Long Hyundai"},
    {"dealerId":"GA076","dealerName":"Thornton Road Hyundai"},
    {"dealerId":"GA092","dealerName":"Mcdonough Hyundai"},
    {"dealerId":"SC027","dealerName":"Dick Smith Hyundai"},
    {"dealerId":"SC039","dealerName":"Hyundai of Greer"},
    {"dealerId":"SC049","dealerName":"Autonation Hyundai Columbia"},
    {"dealerId":"AL008","dealerName":"Hyundai of Dothan"},
    {"dealerId":"AL015","dealerName":"Capitol Hyundai"},
    {"dealerId":"AL025","dealerName":"Mitchell Hyundai"},
    {"dealerId":"AL035","dealerName":"Hyundai of Auburn"},
    {"dealerId":"FL080","dealerName":"Allen Turner Hyundai"},
    {"dealerId":"FL150","dealerName":"Bay Hyundai"},
    {"dealerId":"GA075","dealerName":"Autonation Hyundai Columbus"},
    {"dealerId":"FL126","dealerName":"Red Hoagland Hyundai"},
    {"dealerId":"FL057","dealerName":"King Hyundai"},
    {"dealerId":"FL121","dealerName":"Napleton's Hyundai"},
    {"dealerId":"FL123","dealerName":"Napleton's North Palm Hyundai"},
    {"dealerId":"FL133","dealerName":"Braman Hyundai"},
    {"dealerId":"FL074","dealerName":"Daytona Hyundai"},
    {"dealerId":"FL089","dealerName":"Jenkins Hyundai"},
    {"dealerId":"FL112","dealerName":"Wallace Hyundai"},
    {"dealerId":"FL120","dealerName":"Jenkins Hyundai of Leesburg"},
    {"dealerId":"FL124","dealerName":"Greenway Hyundai Orlando"},
    {"dealerId":"FL152","dealerName":"Wallace Hyundai of Fort Pierce"},
    {"dealerId":"FL135","dealerName":"Westside Hyundai"},
    {"dealerId":"FL144","dealerName":"Jenkins Hyundai of Jacksonville"},
    {"dealerId":"GA066","dealerName":"Langdale Hyundai of South Georgia"},
    {"dealerId":"GA083","dealerName":"Hyundai Brunswick"},
    {"dealerId":"GA090","dealerName":"Autonation Hyundai Savannah"},
    {"dealerId":"SC050","dealerName":"Autonation Hyundai Hilton Head"},
    {"dealerId":"WA025","dealerName":"Korum Hyundai"},
    {"dealerId":"WA033","dealerName":"Titus-Will Hyundai"},
    {"dealerId":"WA035","dealerName":"Speck Hyundai of Tri-Cities"},
    {"dealerId":"WA048","dealerName":"Jack Carroll's Skagit Hyundai"},
    {"dealerId":"WA054","dealerName":"Lee Johnson Hyundai of Everett"},
    {"dealerId":"WA056","dealerName":"Hyundai of Kirkland"},
    {"dealerId":"AK003","dealerName":"Lithia Hyundai of Anchorage"},
    {"dealerId":"OR015","dealerName":"Withnell Hyundai"},
    {"dealerId":"OR030","dealerName":"Eugene Hyundai"},
    {"dealerId":"CA01J","dealerName":"Visalia Hyundai"},
    {"dealerId":"CA172","dealerName":"Lithia Hyundai of Fresno"},
    {"dealerId":"CA173","dealerName":"Selma Hyundai"},
    {"dealerId":"CA308","dealerName":"Future Hyundai of Concord"},
    {"dealerId":"CA197","dealerName":"Riverside Hyundai"},
    {"dealerId":"CA340","dealerName":"Palm Springs Hyundai"},
    {"dealerId":"NV030","dealerName":"Centennial Hyundai"},
    {"dealerId":"NV031","dealerName":"ABC Hyundai"},
    {"dealerId":"CA01L","dealerName":"Hyundai of Glendale"},
    {"dealerId":"CA384","dealerName":"Westlake Hyundai"},
    {"dealerId":"CA391","dealerName":"Bakersfield Hyundai"},
    {"dealerId":"CA393","dealerName":"Keyes Hyundai of Van Nuys"},
    {"dealerId":"CA400","dealerName":"Keyes Hyundai of Mission Hills"},
    {"dealerId":"CA321","dealerName":"Puente Hills Hyundai"},
    {"dealerId":"CA325","dealerName":"Tustin Hyundai"},
    {"dealerId":"CA335","dealerName":"South Bay Hyundai"},
    {"dealerId":"CA375","dealerName":"Russell Westbrook Hyundai Gg"},
    {"dealerId":"CA376","dealerName":"Huntington Beach Hyundai"},
    {"dealerId":"CA377","dealerName":"Russell Westbrook Hyundai Anaheim"},
    {"dealerId":"CA01C","dealerName":"Pedder Hyundai of Poway"},
    {"dealerId":"CA282","dealerName":"Kearny Mesa Hyundai"},
    {"dealerId":"CA372","dealerName":"Imperial Valley Hyundai"},
    {"dealerId":"CA389","dealerName":"Hyundai of El Cajon"},
    {"dealerId":"HI018","dealerName":"Maui Hyundai"},
    {"dealerId":"OH050","dealerName":"Mathews Hyundai"},
    {"dealerId":"NY099","dealerName":"Royal Hyundai of Oneonta"},
    {"dealerId":"PA055","dealerName":"Dickson City Hyundai"},
    {"dealerId":"AR028","dealerName":"Crain Hyundai Little Rock"},
    {"dealerId":"AR029","dealerName":"Crain Hyundai North Little Rock"},
    {"dealerId":"AR034","dealerName":"Crain Hyundai of Fayetteville"},
    {"dealerId":"AR035","dealerName":"Crain Hyundai of Bentonville"},
    {"dealerId":"AR036","dealerName":"Crain Hyundai of Fort Smith"},
    {"dealerId":"TX105","dealerName":"Lithia Hyundai of Odessa"},
    {"dealerId":"TX197","dealerName":"Killeen Hyundai"},
    {"dealerId":"NC072","dealerName":"Johnson Hyundai of Wake Forest"},
    {"dealerId":"PA024","dealerName":"Lehigh Valley Hyundai"},
    {"dealerId":"VA028","dealerName":"Alexandria Hyundai"},
    {"dealerId":"WV023","dealerName":"Friendship Hyundai of Beckley"},
    {"dealerId":"NY120","dealerName":"Star Hyundai"},
    {"dealerId":"NY062","dealerName":"Fuccillo Hyundai of Watertown"},
    {"dealerId":"VA052","dealerName":"Safford Hyundai of Springfield"},
    {"dealerId":"CT037","dealerName":"Balise Hyundai of Fairfield"},
    {"dealerId":"NC040","dealerName":"Carolina Hyundai of High Point"},
    {"dealerId":"TX063","dealerName":"Eckert Hyundai, Inc."},
    {"dealerId":"MD035","dealerName":"Preston Hyundai"},
    {"dealerId":"PA012","dealerName":"Moon Township Hyundai"},
    {"dealerId":"KY028","dealerName":"Hyundai of Louisville"},
    {"dealerId":"TX180","dealerName":"College Station Hyundai"},
    {"dealerId":"TX195","dealerName":"Northwest Hyundai"},
    {"dealerId":"TX196","dealerName":"West Houston Hyundai"},
    {"dealerId":"FL008","dealerName":"Potamkin Hyundai"},
    {"dealerId":"IL014","dealerName":"Auffenberg Hyundai"},
    {"dealerId":"MO036","dealerName":"Fletcher Hyundai"},
    {"dealerId":"OK027","dealerName":"Carter County Hyundai"},
    {"dealerId":"MS025","dealerName":"Mack Grubbs Hyundai"},
    {"dealerId":"SC051","dealerName":"Terry Lambert Hyundai"},
    {"dealerId":"OH072","dealerName":"Taylor Hyundai of Findlay"},
    {"dealerId":"FL151","dealerName":"Delray Hyundai"},
    {"dealerId":"WA020","dealerName":"Doug's Hyundai"},
    {"dealerId":"NJ048","dealerName":"Wayne Auto Mall Hyundai"},
    {"dealerId":"IL093","dealerName":"Hyundai of Lincolnwood"},
    {"dealerId":"IL094","dealerName":"Hyundai of Palatine"},
    {"dealerId":"IA009","dealerName":"Edwards Hyundai"},
    {"dealerId":"NH017","dealerName":"Irwin Hyundai"},
    {"dealerId":"NY050","dealerName":"Riverhead Hyundai"},
    {"dealerId":"WV008","dealerName":"Superior Hyundai"},
    {"dealerId":"KS018","dealerName":"Max Hyundai of Manhattan Llc"},
    {"dealerId":"OK015","dealerName":"Automax Hyundai"},
    {"dealerId":"OK024","dealerName":"Automax Hyundai of Norman"},
    {"dealerId":"GA084","dealerName":"Potamkin Hyundai Stone Mountain"},
    {"dealerId":"WA019","dealerName":"Robert Larson's Hyundai"},
    {"dealerId":"OR025","dealerName":"Beaverton Hyundai"},
    {"dealerId":"CA01A","dealerName":"Laguna Niguel Hyundai"},
    {"dealerId":"NC057","dealerName":"Hyundai of New Bern"},
    {"dealerId":"TX184","dealerName":"Hiley Hyundai West"},
    {"dealerId":"TX111","dealerName":"Humble Hyundai"},
    {"dealerId":"OH066","dealerName":"Taylor Hyundai"},
    {"dealerId":"KY021","dealerName":"Don Moore Hyundai"},
    {"dealerId":"KY033","dealerName":"Cronin Hyundai  of Nicholasville"},
    {"dealerId":"PA058","dealerName":"Auto Land Hyundai of Uniontown"},
    {"dealerId":"CO030","dealerName":"Mcdonald Auto Group Hyundai"},
    {"dealerId":"CO048","dealerName":"Crossroads Hyundai"},
    {"dealerId":"NE005","dealerName":"Sid Dillon Hyundai"},
    {"dealerId":"TX116","dealerName":"Don Davis Hyundai"},
    {"dealerId":"GA026","dealerName":"Taylor Hyundai"},
    {"dealerId":"OR039","dealerName":"Tonkin Gladstone Hyundai"},
    {"dealerId":"CA01F","dealerName":"Fremont Hyundai"},
    {"dealerId":"CA01G","dealerName":"Stevens Creek Hyundai"},
    {"dealerId":"CA304","dealerName":"Capitol Hyundai"},
    {"dealerId":"CA320","dealerName":"Dublin Hyundai"},
    {"dealerId":"WI010","dealerName":"Ken Vance Hyundai"},
    {"dealerId":"OH061","dealerName":"Classic Hyundai"},
    {"dealerId":"GA089","dealerName":"Sutherlin Hyundai Athens"},
    {"dealerId":"CA01D","dealerName":"Fontana Hyundai"},
    {"dealerId":"IL090","dealerName":"O'brien Hyundai of Normal"},
    {"dealerId":"NJ062","dealerName":"Sport Hyundai"},
    {"dealerId":"ND011","dealerName":"Hyundai of Mandan"},
    {"dealerId":"MN026","dealerName":"Hyundai of Mankato"},
    {"dealerId":"ID019","dealerName":"Midway Hyundai"},
    {"dealerId":"WA051","dealerName":"Auburn Hyundai"},
    {"dealerId":"WA053","dealerName":"Seattle Hyundai"},
    {"dealerId":"WA058","dealerName":"Bud Clary Hyundai"},
    {"dealerId":"CA373","dealerName":"Hyundai of Moreno Valley"},
    {"dealerId":"UT017","dealerName":"Young Hyundai"},
    {"dealerId":"MO030","dealerName":"Suntrup Hyundai"},
    {"dealerId":"NY077","dealerName":"Towne Hyundai"},
    {"dealerId":"MA053","dealerName":"Balise Hyundai"},
    {"dealerId":"LA024","dealerName":"Eddie Tourelle's Northpark Hyundai"},
    {"dealerId":"FL130","dealerName":"Tamiami Hyundai"},
    {"dealerId":"IA020","dealerName":"Vern Eide Hyundai Sioux City"},
    {"dealerId":"FL128","dealerName":"Werner Hyundai"},
    {"dealerId":"FL153","dealerName":"Doral Hyundai"},
    {"dealerId":"FL154","dealerName":"Lehman Hyundai"},
    {"dealerId":"CA102","dealerName":"Northwood Hyundai"},
    {"dealerId":"CA387","dealerName":"Premier Hyundai of Seaside"},
    {"dealerId":"CT007","dealerName":"M. J. Sullivan Hyundai"},
    {"dealerId":"TX175","dealerName":"Hiley Hyundai of Burleson"},
    {"dealerId":"HI015","dealerName":"Big Island Hyundai"},
    {"dealerId":"UT016","dealerName":"Ken Garff Hyundai Southtowne"},
    {"dealerId":"CA341","dealerName":"Hanlees Hilltop Hyundai"},
    {"dealerId":"NY139","dealerName":"West Herr Hyundai"},
    {"dealerId":"VA047","dealerName":"Williamsburg Hyundai"},
    {"dealerId":"WV006","dealerName":"Joe Holland Hyundai"},
    {"dealerId":"NY149","dealerName":"Vision Hyundai Webster"},
    {"dealerId":"NY151","dealerName":"Vision Hyundai Canandaigua"},
    {"dealerId":"NY150","dealerName":"Vision Hyundai Henrietta"},
    {"dealerId":"FL091","dealerName":"Gettel Hyundai of Sarasota"},
    {"dealerId":"FL136","dealerName":"Gettel Hyundai of Lakewood"},
    {"dealerId":"FL139","dealerName":"Gettel Hyundai of Charlotte County"},
    {"dealerId":"IL018","dealerName":"Green Hyundai"},
    {"dealerId":"NY146","dealerName":"Empire Hyundai of New Rochelle"},
    {"dealerId":"IL044","dealerName":"Gregory Hyundai"},
    {"dealerId":"IL076","dealerName":"Wilkins Hyundai"},
    {"dealerId":"IL087","dealerName":"Rosen Hyundai"},
    {"dealerId":"IL052","dealerName":"Pugi Hyundai"},
    {"dealerId":"IL063","dealerName":"Family Hyundai"},
    {"dealerId":"KY017","dealerName":"Gates Hyundai"},
    {"dealerId":"KY018","dealerName":"Oxmoor Hyundai"},
    {"dealerId":"KY031","dealerName":"Don Franklin Lexington Hyundai"},
    {"dealerId":"OH011","dealerName":"Ron Marhofer Hyundai"},
    {"dealerId":"OH047","dealerName":"Preston Hyundai"},
    {"dealerId":"OH053","dealerName":"Ron Marhofer Hyundai"},
    {"dealerId":"OH060","dealerName":"Great Lakes Hyundai"},
    {"dealerId":"OH078","dealerName":"Spitzer Hyundai"},
    {"dealerId":"WI014","dealerName":"Boucher Hyundai"},
    {"dealerId":"WI016","dealerName":"Zimbrick Hyundai West"},
    {"dealerId":"WI022","dealerName":"John Amato Hyundai"},
    {"dealerId":"WI030","dealerName":"Broadway Hyundai"},
    {"dealerId":"WI035","dealerName":"Boucher Hyundai of Janesville"},
    {"dealerId":"WI039","dealerName":"Rosen Hyundai Greenfield"},
    {"dealerId":"WI041","dealerName":"Amato Hyundai of Glendale"},
    {"dealerId":"MN005","dealerName":"Adamson Hyundai"},
    {"dealerId":"MN017","dealerName":"Buerkle Hyundai"},
    {"dealerId":"MN025","dealerName":"Morrie's 394 Hyundai"},
    {"dealerId":"MN027","dealerName":"Inver Grove Hyundai"},
    {"dealerId":"OH027","dealerName":"Dennis Hyundai"},
    {"dealerId":"OH037","dealerName":"Ricart Hyundai"},
    {"dealerId":"OH057","dealerName":"Dennis Hyundai of Dublin"},
    {"dealerId":"OH063","dealerName":"Herrnstein Hyundai"},
    {"dealerId":"OH077","dealerName":"Germain Hyundai"},
    {"dealerId":"IL096","dealerName":"Kunes Hyundai of Sycamore"},
    {"dealerId":"MI023","dealerName":"Maple Hill Hyundai"},
    {"dealerId":"IL030","dealerName":"Mike Miller Hyundai"},
    {"dealerId":"IL092","dealerName":"Kunes Country Hyundai of Quincy"},
    {"dealerId":"MO016","dealerName":"St. Charles Hyundai"},
    {"dealerId":"MI033","dealerName":"Garber Hyundai"},
    {"dealerId":"MI039","dealerName":"Randy Wise Hyundai"},
    {"dealerId":"MI048","dealerName":"Feldman Hyundai of New Hudson"},
    {"dealerId":"MI056","dealerName":"Elder Hyundai"},
    {"dealerId":"MI059","dealerName":"Williams Hyundai"},
    {"dealerId":"MI060","dealerName":"Suburban Hyundai of Troy"},
    {"dealerId":"ME002","dealerName":"Quirk Hyundai of Bangor"},
    {"dealerId":"NH011","dealerName":"Grappone Hyundai"},
    {"dealerId":"MA030","dealerName":"Herb Connolly Hyundai"},
    {"dealerId":"MA068","dealerName":"Quirk Hyundai"},
    {"dealerId":"NH012","dealerName":"Nashua Hyundai"},
    {"dealerId":"MA058","dealerName":"Balise Hyundai of Cape Cod"},
    {"dealerId":"RI012","dealerName":"Grieco Hyundai"},
    {"dealerId":"CT020","dealerName":"Lia Hyundai"},
    {"dealerId":"NY106","dealerName":"Curry Hyundai"},
    {"dealerId":"NY058","dealerName":"Plaza Hyundai"},
    {"dealerId":"NY107","dealerName":"Hyundai 112"},
    {"dealerId":"NY127","dealerName":"South Shore Hyundai"},
    {"dealerId":"NY144","dealerName":"Koeppel Hyundai"},
    {"dealerId":"NY147","dealerName":"Empire Hyundai of Jamaica"},
    {"dealerId":"NJ005","dealerName":"Towne Hyundai"},
    {"dealerId":"NJ033","dealerName":"Paramus Hyundai"},
    {"dealerId":"NJ034","dealerName":"Sansone's Route 1 Hyundai"},
    {"dealerId":"NJ045","dealerName":"Liberty Hyundai"},
    {"dealerId":"NJ051","dealerName":"Lynnes Hyundai Llc"},
    {"dealerId":"NY109","dealerName":"City World Hyundai"},
    {"dealerId":"NY129","dealerName":"Rockland Hyundai"},
    {"dealerId":"NY133","dealerName":"Island Hyundai"},
    {"dealerId":"NY143","dealerName":"Hyundai City of Bay Ridge"},
    {"dealerId":"NJ032","dealerName":"Lester Glenn Hyundai"},
    {"dealerId":"NJ063","dealerName":"Hyundai of Trenton"},
    {"dealerId":"NJ064","dealerName":"Burlington Hyundai"},
    {"dealerId":"DE008","dealerName":"Winner Hyundai"},
    {"dealerId":"PA003","dealerName":"Pacifico Hyundai"},
    {"dealerId":"PA034","dealerName":"Springfield Hyundai"},
    {"dealerId":"PA071","dealerName":"Piazza Hyundai of Pottstown"},
    {"dealerId":"PA079","dealerName":"Faulkner Hyundai"},
    {"dealerId":"PA094","dealerName":"Hyundai of Abington"},
    {"dealerId":"PA032","dealerName":"Freysinger Hyundai"},
    {"dealerId":"PA076","dealerName":"Faulkner Hyundai"},
    {"dealerId":"PA090","dealerName":"Kelly Hyundai"},
    {"dealerId":"PA092","dealerName":"Bennett Hyundai of Lebanon"},
    {"dealerId":"PA037","dealerName":"Wright Hyundai"},
    {"dealerId":"PA043","dealerName":"#1 Cochran Hyundai"},
    {"dealerId":"PA044","dealerName":"Bowser Hyundai"},
    {"dealerId":"PA062","dealerName":"Washington Hyundai"},
    {"dealerId":"PA063","dealerName":"#1 Cochran Hyu of South Hills"},
    {"dealerId":"PA073","dealerName":"Bowser Hyundai"},
    {"dealerId":"NY036","dealerName":"Prestige Hyundai"},
    {"dealerId":"CT028","dealerName":"Lia Hyundai"},
    {"dealerId":"NY041","dealerName":"Garvey Hyundai"},
    {"dealerId":"NY078","dealerName":"Lia Hyundai"},
    {"dealerId":"MD008","dealerName":"Thompson Hyundai"},
    {"dealerId":"MD013","dealerName":"Ideal Hyundai"},
    {"dealerId":"MD022","dealerName":"Bob Bell Hyundai"},
    {"dealerId":"MD032","dealerName":"Antwerpen Hyundai Columbia"},
    {"dealerId":"MD036","dealerName":"Thomas Hyundai"},
    {"dealerId":"MD017","dealerName":"College Park Hyundai"},
    {"dealerId":"MD020","dealerName":"Fitzgeralds Lakeforest Hyundai"},
    {"dealerId":"MD034","dealerName":"Fitzgerald Hyundai"},
    {"dealerId":"MD041","dealerName":"Sheehy Hyundai of Waldorf"},
    {"dealerId":"MD043","dealerName":"Ourisman Hyundai of Bowie"},
    {"dealerId":"VA059","dealerName":"Safford Brown Hyundai Manassas"},
    {"dealerId":"VA060","dealerName":"Safford Brown Hyundai Leesburg"},
    {"dealerId":"VA061","dealerName":"Safford Brown Hyundai Fairfax"},
    {"dealerId":"VA004","dealerName":"Checkered Flag Hyundai World"},
    {"dealerId":"VA055","dealerName":"Hyundai of Hampton"},
    {"dealerId":"WV002","dealerName":"Straub Hyundai"},
    {"dealerId":"WV022","dealerName":"Friendship Hyundai of Princeton"},
    {"dealerId":"VA056","dealerName":"Southern Team Hyundai"},
    {"dealerId":"NC008","dealerName":"Lee Hyundai"},
    {"dealerId":"NC030","dealerName":"Sport Durst Hyundai"},
    {"dealerId":"NC047","dealerName":"Johnson Hyundai"},
    {"dealerId":"NC049","dealerName":"Parkway Hyundai"},
    {"dealerId":"NC070","dealerName":"Deacon Jones Hyundai"},
    {"dealerId":"NC021","dealerName":"Hunter Hyundai"},
    {"dealerId":"NC048","dealerName":"Modern Hyundai of Concord"},
    {"dealerId":"NC060","dealerName":"Hyundai of Asheville"},
    {"dealerId":"CO032","dealerName":"Planet Hyundai"},
    {"dealerId":"CO038","dealerName":"Phil Long Hyundai of Motor City"},
    {"dealerId":"CO040","dealerName":"Hyundai of Greeley"},
    {"dealerId":"CO050","dealerName":"Schomp Hyundai"},
    {"dealerId":"AZ028","dealerName":"Horne Hyundai"},
    {"dealerId":"AZ035","dealerName":"San Tan Hyundai"},
    {"dealerId":"AZ042","dealerName":"Earnhardt Hyundai"},
    {"dealerId":"AZ043","dealerName":"Earnhardt Hyundai North Scottsdale"},
    {"dealerId":"AZ047","dealerName":"Camelback Hyundai"},
    {"dealerId":"UT013","dealerName":"Murdock Hyundai"},
    {"dealerId":"UT014","dealerName":"Hyundai Murdock of Logan"},
    {"dealerId":"UT015","dealerName":"Murdock Hyundai Murray, Llc"},
    {"dealerId":"AZ003","dealerName":"Jim Click Hyundai Auto Mall"},
    {"dealerId":"AZ018","dealerName":"Jim Click Hyundai Eastside"},
    {"dealerId":"AZ031","dealerName":"Jim Click Hyundai of Green Valley"},
    {"dealerId":"NM014","dealerName":"Roswell Hyundai"},
    {"dealerId":"ID020","dealerName":"Stone's Hyundai"},
    {"dealerId":"MO042","dealerName":"Reliable Hyundai"},
    {"dealerId":"MO045","dealerName":"Joe Machens Hyundai"},
    {"dealerId":"OK030","dealerName":"Bob Howard Hyundai"},
    {"dealerId":"LA026","dealerName":"All Star Hyu of Baton Rouge"},
    {"dealerId":"LA027","dealerName":"Greg Leblanc Hyundai"},
    {"dealerId":"LA028","dealerName":"Bill Hood Hyundai"},
    {"dealerId":"LA030","dealerName":"Sterling Hyundai"},
    {"dealerId":"LA033","dealerName":"Hyundai of Metairie"},
    {"dealerId":"TX177","dealerName":"Hyundai of Silsbee"},
    {"dealerId":"LA032","dealerName":"Mike Morgan Hyundai"},
    {"dealerId":"MS026","dealerName":"Columbus Hyundai"},
    {"dealerId":"TX183","dealerName":"Clay Cooley Hyundai of Rockwall"},
    {"dealerId":"TX189","dealerName":"Clay Cooley Hyundai Mesquite"},
    {"dealerId":"TX090","dealerName":"Gene Messer Hyundai"},
    {"dealerId":"TX098","dealerName":"Star Hyundai"},
    {"dealerId":"TX104","dealerName":"North Freeway Hyundai"},
    {"dealerId":"TX145","dealerName":"Sterling Mccall Hyundai"},
    {"dealerId":"TX150","dealerName":"Demontrond Hyundai"},
    {"dealerId":"TX169","dealerName":"Baytown Hyundai"},
    {"dealerId":"TX203","dealerName":"Steele South Loop Hyundai"},
    {"dealerId":"TX016","dealerName":"Red Mccombs Hyundai"},
    {"dealerId":"TX127","dealerName":"Red Mccombs Hyundai Northwest"},
    {"dealerId":"TX160","dealerName":"Tipton Hyundai"},
    {"dealerId":"TX190","dealerName":"Principle Hyundai  Boerne"},
    {"dealerId":"TX201","dealerName":"Steele Hyundai New Braunfels"},
    {"dealerId":"TN032","dealerName":"Friendship Hyundai of Bristol"},
    {"dealerId":"TN035","dealerName":"Friendship Hyundai of Johnson City"},
    {"dealerId":"TN049","dealerName":"Wolfchase Hyundai"},
    {"dealerId":"GA002","dealerName":"Rick Case Hyundai"},
    {"dealerId":"GA038","dealerName":"Hyundai of Kennesaw"},
    {"dealerId":"GA041","dealerName":"Rick Case Hyundai"},
    {"dealerId":"GA063","dealerName":"Terry Reid Hyundai"},
    {"dealerId":"GA087","dealerName":"Hyundai of Cumming"},
    {"dealerId":"AL021","dealerName":"Superior Hyundai"},
    {"dealerId":"GA015","dealerName":"Southtowne Hyundai"},
    {"dealerId":"GA029","dealerName":"Five Star Hyundai"},
    {"dealerId":"GA035","dealerName":"Southtowne Hyundai of Newnan"},
    {"dealerId":"GA037","dealerName":"Jim Ellis Hyundai"},
    {"dealerId":"GA057","dealerName":"Five Star Hyundai of Warner Robins"},
    {"dealerId":"SC018","dealerName":"Myrtle Beach Hyundai"},
    {"dealerId":"SC042","dealerName":"Hyundai of Anderson"},
    {"dealerId":"AL029","dealerName":"Palmer's Airport Hyundai"},
    {"dealerId":"FL087","dealerName":"Hampton Hyundai"},
    {"dealerId":"GA081","dealerName":"Five Star Hyundai Albany, Llc"},
    {"dealerId":"FL062","dealerName":"Fitzgerald's Cntrysde Hyundai"},
    {"dealerId":"FL073","dealerName":"Lakeland Hyundai"},
    {"dealerId":"FL076","dealerName":"Crown Hyundai"},
    {"dealerId":"FL143","dealerName":"Brandon Hyundai"},
    {"dealerId":"FL146","dealerName":"Hyundai of Venice"},
    {"dealerId":"FL147","dealerName":"Hyundai of Fort Myers"},
    {"dealerId":"FL005","dealerName":"Rick Case Hyundai"},
    {"dealerId":"FL114","dealerName":"Rick Case Hyundai"},
    {"dealerId":"FL122","dealerName":"Coconut Creek Hyundai"},
    {"dealerId":"FL142","dealerName":"Homestead Hyundai"},
    {"dealerId":"FL049","dealerName":"Coastal Hyundai"},
    {"dealerId":"FL098","dealerName":"Cocoa Hyundai"},
    {"dealerId":"FL101","dealerName":"Route 60 Hyundai"},
    {"dealerId":"FL103","dealerName":"Universal Hyundai"},
    {"dealerId":"FL137","dealerName":"Headquarter Hyundai"},
    {"dealerId":"FL109","dealerName":"Parks Hyundai of Gainesville"},
    {"dealerId":"FL140","dealerName":"Key Hyundai"},
    {"dealerId":"WA050","dealerName":"Car Pros Renton Hyundai"},
    {"dealerId":"WA057","dealerName":"Hyundai of Yakima"},
    {"dealerId":"NV029","dealerName":"Carson City Hyundai"},
    {"dealerId":"OR033","dealerName":"Dick Hannah's Hyundai of Portland"},
    {"dealerId":"OR038","dealerName":"Ron Tonkin Hyundai"},
    {"dealerId":"WA007","dealerName":"Vancouver Hyundai"},
    {"dealerId":"CA129","dealerName":"Chico Hyundai"},
    {"dealerId":"CA232","dealerName":"Hyundai of Roseville"},
    {"dealerId":"CA307","dealerName":"Central Valley Hyundai"},
    {"dealerId":"CA315","dealerName":"Elk Grove Hyundai"},
    {"dealerId":"CA339","dealerName":"Folsom Lake Hyundai"},
    {"dealerId":"CA350","dealerName":"Stockton Hyundai"},
    {"dealerId":"CA362","dealerName":"Hyundai of Vacaville"},
    {"dealerId":"CA386","dealerName":"Platinum Hyundai"},
    {"dealerId":"CA01I","dealerName":"Vallejo Hyundai"},
    {"dealerId":"CA033","dealerName":"Manly Hyundai"},
    {"dealerId":"CA331","dealerName":"All Star Hyundai"},
    {"dealerId":"CA121","dealerName":"Ontario Hyundai"},
    {"dealerId":"CA134","dealerName":"Gosch Hyundai"},
    {"dealerId":"CA309","dealerName":"Hyundai Inland Empire"},
    {"dealerId":"CA311","dealerName":"Hyundai of La Quinta"},
    {"dealerId":"CA366","dealerName":"Temecula Hyundai"},
    {"dealerId":"NV032","dealerName":"Hyundai of Las Vegas"},
    {"dealerId":"NV033","dealerName":"Henderson Hyundai Superstore"},
    {"dealerId":"CA01H","dealerName":"Diamond Hyundai Palmdale"},
    {"dealerId":"CA253","dealerName":"Victorville Hyundai"},
    {"dealerId":"CA349","dealerName":"Winn Hyundai of Santa Maria"},
    {"dealerId":"CA359","dealerName":"Alexander Hyundai of Oxnard"},
    {"dealerId":"CA01E","dealerName":"Harbor Hyundai"},
    {"dealerId":"CA01O","dealerName":"Hyundai of Downtown Los Angeles"},
    {"dealerId":"CA01P","dealerName":"Hyundai Carson"},
    {"dealerId":"CA317","dealerName":"Tuttle Click Hyundai"},
    {"dealerId":"CA323","dealerName":"Norm Reeves Hyu Superstore Cerritos"},
    {"dealerId":"CA020","dealerName":"Frank Hyundai"},
    {"dealerId":"CA379","dealerName":"Hyundai of Escondido"},
    {"dealerId":"CA380","dealerName":"Bob Baker Hyundai"},
    {"dealerId":"HI021","dealerName":"Windward Hyundai"},
    {"dealerId":"HI022","dealerName":"Jerry V's Honolulu Hyundai"},
    {"dealerId":"TX100","dealerName":"Hyundai of El Paso"},
    {"dealerId":"VA011","dealerName":"Price Hyundai Corporation"},
    {"dealerId":"FL118","dealerName":"Hyundai of Orange Park"},
    {"dealerId":"KY006","dealerName":"Swope Hyundai"},
    {"dealerId":"SC045","dealerName":"Stivers Hyundai"},
    {"dealerId":"VA053","dealerName":"Koons Hyundai"},
    {"dealerId":"HI012","dealerName":"Tony Hyundai Waipio"},
    {"dealerId":"CA300","dealerName":"Cardinaleway Hyundai"},
    {"dealerId":"CA01B","dealerName":"Cardinale Way Hyundai of Glendora"},
    {"dealerId":"CA01M","dealerName":"Cardinaleway Hyundai of El Monte"},
    {"dealerId":"AL009","dealerName":"Tuscaloosa Hyundai"},
    {"dealerId":"CA01N","dealerName":"Hyundai of San Bruno"},
    {"dealerId":"MD026","dealerName":"Len Stoler Hyundai"},
    {"dealerId":"KS017","dealerName":"Reed Hyundai"},
    {"dealerId":"MO047","dealerName":"Reed Hyundai"},
    {"dealerId":"TX171","dealerName":"South Point Hyundai"},
    {"dealerId":"VA058","dealerName":"Cma's Hyundai of Lynchburg"},
    {"dealerId":"FL127","dealerName":"Hyundai of St. Augustine"},
    {"dealerId":"WA055","dealerName":"Spokane Hyundai"},
    {"dealerId":"OR035","dealerName":"Dick's Hillsboro Hyundai"},
    {"dealerId":"FL125","dealerName":"Holler Hyundai"},
    {"dealerId":"OK026","dealerName":"James Hodge Hyundai"},
    {"dealerId":"IL083","dealerName":"Green Family Hyundai"},
    {"dealerId":"SC044","dealerName":"Hyundai of North Charleston"},
    {"dealerId":"NJ059","dealerName":"Causeway Hyundai"},
    {"dealerId":"FL148","dealerName":"Hyundai of New Port Richey"},
    {"dealerId":"FL141","dealerName":"Hyundai of Central Florida"},
    {"dealerId":"IL054","dealerName":"Gurnee Hyundai"},
    {"dealerId":"IN007","dealerName":"Bob Rohrman Hyundai"},
    {"dealerId":"IN018","dealerName":"Bob Rohrman Indy Hyundai"},
    {"dealerId":"OH085","dealerName":"Diehl Hyundai of Massillon"},
    {"dealerId":"MN014","dealerName":"Kolar Hyundai"},
    {"dealerId":"PA091","dealerName":"Diehl Hyundai"},
    {"dealerId":"KS003","dealerName":"Hatchett Hyundai"},
    {"dealerId":"KS013","dealerName":"Hatchett Hyundai West"},
    {"dealerId":"OK018","dealerName":"Edmond Hyundai"},
    {"dealerId":"GA068","dealerName":"Volume Hyundai"},
    {"dealerId":"CA392","dealerName":"Hyundai of Gilroy"},
    {"dealerId":"99999","dealerName":"Unknown"},
    ]

    
export default dealersData;