import { createSlice } from '@reduxjs/toolkit'
import { defaultTooltips } from '../../data/defaultTooltips'

const initialState = {
    tooltips: defaultTooltips
}

export const tooltipsSlice = createSlice({
    name: 'tooltips',
    initialState,
    reducers: {
        updatetooltips: (state, action) => {
            state.tooltips = action.payload
        }
    }
})

export const { 
    updatetooltips,
} = tooltipsSlice.actions

export default tooltipsSlice.reducer