import { createSlice } from '@reduxjs/toolkit'
import { VEHICLE_DATA } from "../../data/vehicles/VehicleData";

const initialState = {
    vehicles: VEHICLE_DATA
}

export const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        updateVehicles: (state, action) => {
            state.vehicles = action.payload
        }
    }
})

export const { 
       updateVehicles,
} = vehiclesSlice.actions

export default vehiclesSlice.reducer