import { useState, useEffect } from 'react';
import { request } from 'graphql-request';
import { LIST_TOOLTIPS, LIST_VEHICLES } from '../queries';

const useFetchAmplify = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ tooltips: null, vehicles: null });
  const [token, setToken] = useState({ tooltips: null, vehicles: null });

  let endpoint = process.env.REACT_APP_AMPLIFY_STAGING_ENDPOINT;
  let apiKey = process.env.REACT_APP_AMPLIFY_STAGING_API_KEY;

  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    endpoint = process.env.REACT_APP_AMPLIFY_PRODUCTION_ENDPOINT;
    apiKey = process.env.REACT_APP_AMPLIFY_PRODUCTION_API_KEY;
  }

  useEffect(() => {
    const fetchData = async (nextTokens = { tooltips: null, vehicles: null }) => {
      try {
        const headers = {
          "X-Api-Key": apiKey,
        };
        const [tooltipsResponse, vehiclesResponse] = await Promise.all([ //check if is good to use allSettled
          request(endpoint, LIST_TOOLTIPS, { nextToken: nextTokens.tooltips }, headers),
          request(endpoint, LIST_VEHICLES, { nextToken: nextTokens.vehicles }, headers)
        ]);
  
        setData(prevData => ({
          tooltips: [...(prevData.tooltips || []), ...tooltipsResponse.listVehicleTooltips.items],
          vehicles: [...(prevData.vehicles || []), ...vehiclesResponse.listVehicles.items]
        }));

        setToken({
            tooltips: tooltipsResponse.listVehicleTooltips.nextToken,
            vehicles: vehiclesResponse.listVehicles.nextToken
          });
  
        // Check if there is a next token
        if (tooltipsResponse.listVehicleTooltips.nextToken || vehiclesResponse.listVehicles.nextToken) {
          fetchData({
            tooltips: tooltipsResponse.listVehicleTooltips.nextToken,
            vehicles: vehiclesResponse.listVehicles.nextToken
          });
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [endpoint, apiKey]);

  return { loading, error, data, token };
};

export default useFetchAmplify;

// const { loading, error, data, token } = useFetchAmplify();
