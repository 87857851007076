import Slider from "../Slider"
import { getDealerId, getElectricityPrice, getTooltips } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updateElectricityPrice } from "../../../redux/Slices/userSlice";
import GaTracker from "../../../utils/GaTracker";

const SlideElectricityPrice = () => {
    const dispatch = useDispatch()
    const electricityPrice = useSelector(getElectricityPrice)
    const dealerId = useSelector(getDealerId)
    const maxNumber = 1
    const tooltip = useSelector(getTooltips)

    const updateEPrice = (price) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Electricity Price",
          label: `Updated Electricity Price Slider - ${dealerId}`,
        });

        if (!isNaN(Number(price))) {
            if (price > maxNumber) {
                dispatch(updateElectricityPrice(maxNumber))
            } else {
            dispatch(updateElectricityPrice(price))
            }
        }
    }

    const onBlurUpdateEPrice = (price) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Electricity Price",
          label: `Blur Update Electricity Price Slider - ${dealerId}`,
        });

        if (!isNaN(Number(price))) {
            if (price > maxNumber) {
                dispatch(updateElectricityPrice(maxNumber))
            } else {
            dispatch(updateElectricityPrice(Number(price).toFixed(2)))
            }
        }
    }

    return (
        <Slider 
            title={"Electricity price / kWh"}
            value={electricityPrice}
            onChange={updateEPrice}
            min={.05}
            max={maxNumber}
            step={.01}
            startAdornment={"$"}
            maxLength={4}
            tooltip={tooltip?.electricityPriceTip}
            tooltipPlacement="bottom"
            onBlur={onBlurUpdateEPrice}
        />
    )
}

export default SlideElectricityPrice
