import Grid from '@mui/material/Grid';
import { FormatAsThousands } from '../../utils/Format';

const ModelCard = ({vehicle}) => {

       const getVehicleImage = (vehicle) => {
              return vehicle.images[0].url_full;
       }

       return (
              <Grid alignItems="center" justifyContent="center"
              container className="model-card">
                     <div className="vehicle-image">
                            <img src={getVehicleImage(vehicle)} alt="Vehicle" style={{width:'100%'}} />
                     </div>
                     <div className="vehicle-info">
                            <h2>{vehicle.display_name}</h2>
                            <h3>${FormatAsThousands(vehicle.msrp)} MSRP</h3>
                            <div className="info">
                                   <p>Range&nbsp;:&nbsp;<strong>{vehicle.total_range}</strong> miles</p>
                                   <p>Battery Capacity&nbsp;:&nbsp;<strong>{vehicle.battery_capacity}</strong> kwh</p>
                            </div>
                     </div>
              </Grid>
       )
}

export default ModelCard
