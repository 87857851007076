import { gql } from 'graphql-request'

const LIST_TOOLTIPS = gql`
  query ListVehicleTooltips($nextToken: String) {
    listVehicleTooltips(nextToken: $nextToken) {
      items {
        id
        handle
        incentivesTip
        zipcodeTip
        trimTip
        costOwnershipTip
        mileageTip
        mpgTip
        electricityPriceTip
        fuelPriceTip
        potentialIncentivesTip
        potentialFuelSavingsTip
        emissionsTip
        _deleted
      }
      nextToken
    }
  }
`;

const LIST_VEHICLES = gql`
  query ListVehicles($nextToken: String) {
    listVehicles(nextToken: $nextToken) {
      items {
        id
        handle
        label
        trim
        displayName
        maintenance
        msrp
        _deleted
      }
      nextToken
    }
  }
`;

export { LIST_TOOLTIPS, LIST_VEHICLES }