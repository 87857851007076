import { Typography } from "@mui/material"
import CustomizedToolTip from "./CustomizedToolTip/CustomizedToolTip"

const TabTitle = ({ header, subHeader, tooltip, tooltipPlacement }) => {
    return (
        <>
            <Typography
                sx={{ fontFamily: "HyundaiSansHead", fontSize: "1.25rem", lineHeight: "1.625rem", fontWeight: 700, textAlign: "left", paddingBottom: "1rem" }}
                variant="h1"
            >
                <div style={{display:"inline"}} dangerouslySetInnerHTML={{__html: header}} />
                {tooltip && <CustomizedToolTip title={tooltip} placement={tooltipPlacement}/>}
            </Typography>

            <Typography sx={{ fontSize: 16, fontWeight: 400, textAlign: "left", lineHeight:"1.375rem", color:"#666666", height: '70px'  }} variant="h2">{subHeader}</Typography>
        </>
    )
}

export default TabTitle
