import Slider from "../Slider"
import { getDealerId, getFuelPrice, getTooltips } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updateFuelPrice } from "../../../redux/Slices/userSlice";
import GaTracker from "../../../utils/GaTracker";

const SlideFuelPrice = () => {
    const dispatch = useDispatch()
    const fuelPrice = useSelector(getFuelPrice)
    const dealerId = useSelector(getDealerId)
    const maxNumber = 10
    const tooltip = useSelector(getTooltips)

    const updateFuelCost = (price) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Fuel Price",
          label: `Updated Fuel Price Slider - ${dealerId}`,
        });

        if (!isNaN(Number(price))) {
            if (price > maxNumber) {
                dispatch(updateFuelPrice(maxNumber))
            } else {
                dispatch(updateFuelPrice(price))
            } 
        }
    }

    const onBlurUpdateFuelCost = (price) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Fuel Price",
          label: `Blur Update Fuel Price Slider - ${dealerId}`,
        });

        if (!isNaN(Number(price))) {
            if (price > maxNumber) {
                dispatch(updateFuelPrice(maxNumber))
            } else {
            dispatch(updateFuelPrice(Number(price).toFixed(2)))
            }
        }
    }

    return (
        <Slider 
            title={"Fuel price / gallon"}
            value={fuelPrice}
            onChange={updateFuelCost}
            min={2}
            max={maxNumber}
            step={.05}
            startAdornment={"$"}
            maxLength={4}
            tooltip={tooltip?.fuelPriceTip}
            tooltipPlacement="bottom"
            onBlur={onBlurUpdateFuelCost}
        />
    )
}

export default SlideFuelPrice
