import Select from "../Select"
// import { VEHICLE_DATA } from "../../../data/vehicles/VehicleData";
import { getActiveVehicle, getDealerId, getVehicles } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updateTrims, updateActiveTrim, updateActiveVehicle } from "../../../redux/Slices/userSlice";
import GaTracker from "../../../utils/GaTracker";

const SelectVehicle = () => {
       const dispatch = useDispatch()
       const activeVehicle = useSelector(getActiveVehicle);
       const vehicles = useSelector(getVehicles);
       const dealerId = useSelector(getDealerId)

       const options = vehicles.map(vehicle => {
              return {
                     value: vehicle.id,
                     label: vehicle.label
              }
       })

       const changeVehicle = (vehicleID) => {
              const newVehicle = vehicles.find(vehicle => vehicle.id === vehicleID);

              GaTracker.trackEvent({
                     category: "Select Vehicle",
                     action: "Selected a Vehicle",
                     label: `Clicked ${newVehicle.name} - ${dealerId}`
              });

              dispatch(updateActiveTrim(newVehicle.trims[0]))
              dispatch(updateActiveVehicle(newVehicle))
              dispatch(updateTrims(newVehicle.trims))
       }

       return (
              <Select
                     title="Electric Vehicle"
                     options={options}
                     value={activeVehicle.id}
                     onChange={changeVehicle}
              />
       );
}

export default SelectVehicle
