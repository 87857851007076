import Select from "../Select"
import { getActivePurchaseMethod } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updateActivePurchaseMethod } from '../../../redux/Slices/userSlice';
import GaTracker from "../../../utils/GaTracker";

const SelectPurchaseMethod = () => {
    const dispatch = useDispatch()
    const activePurchaseMethod = useSelector(getActivePurchaseMethod)
    const purchaseMethods = [
       {
              name: "Cash",
              value: "cash"
       },
       {
              name: "Lease",
              value: "lease"
       },
    ]
    const options = purchaseMethods.map(method => {
        return {
            value: method.value,
            label: method.name,
        }
    })
    const changePurchaseMethod = (purchaseMethod) => {

        GaTracker.trackEvent({
            category: "Select Purchase Method",
            action: "Selected Purchase Method",
            label: `Clicked ${purchaseMethod}`
        });

       dispatch(updateActivePurchaseMethod(purchaseMethod))
    }

    return (
      <Select
        title="Purchase Method"
        options={options}
        value={activePurchaseMethod}
        onChange={changePurchaseMethod}
        tooltipPlacement="bottom"
      />
    );
}

export default SelectPurchaseMethod