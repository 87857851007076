import TextField from "../TextField"
import { getDealerId, getZipcode, getTooltips } from "../../../redux/selectors"
import { useDispatch, useSelector } from 'react-redux';
import { updateZipcode } from '../../../redux/Slices/userSlice';
import GaTracker from "../../../utils/GaTracker";


const TextFieldZipcode = () => {
    const zipcode = useSelector(getZipcode)
    const dealerId= useSelector(getDealerId)
    const dispatch = useDispatch()
    const tooltip = useSelector(getTooltips)

    const changeZipcode = (zipcode) => {
        GaTracker.trackEvent({
          category: "Update",
          action: "Updated Zip code",
          label: `Updated Zip code Input - ${dealerId}`,
        });

        dispatch(updateZipcode(zipcode))
    }
    return (
        <TextField 
            title="ZIP code"
            defaultValue={zipcode}
            onChange={changeZipcode}
            maxLength={5}
            tooltip={tooltip?.zipcodeTip}
            placement="bottom"
        />
    )
}

export default TextFieldZipcode
