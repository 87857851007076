import "./App.css";
import { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { useDispatch } from "react-redux";
import { updatetooltips } from "./redux/Slices/tooltipsSlice";

//TABS
import Incentives from "./tabs/Incentives";
import Emissions from "./tabs/Emissions";
import CostOfOwnership from "./tabs/CostOfOwnership";

//DATA AND UTILS
import GaTracker from "./utils/GaTracker";
import Footer from "./components/Footer/Footer";
import { defaultTooltips } from "./data/defaultTooltips";

import useFetchAmplify from "./graphql/hooks/useFetchAmplify";
import useGetData from "./utils/useGetData";

function App() {
  const dispatch = useDispatch();
  const {
    isLoading,
    isError,
    error,
    setVehicles,
    tabName,
    showBranding,
    showTabName,
    showZipCode,
    showModelCard,
    activeTrim,
    validDealerId,
    vehicleData,
    locationData,
  } = useGetData();

  const [value, setValue] = useState(0);
  const [messages, setMessages] = useState({});
  const {
    loading: isLoadingAmplify,
    data: amplifyData,
    token,
  } = useFetchAmplify();

  useEffect(() => {
    // Set the page title when the component mounts
    document.title = "Hyundai EV Portal Widget";
    GaTracker.initialize();
  }, [activeTrim, validDealerId]);

  const handleChange = (event, newValue) => {
    GaTracker.trackEvent({
      category: "Change Tab",
      action: "Changed the Tab",
      label: `Changed to ${tabName}`,
    });

    setValue(newValue);
  };

  //amplify
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isLoadingAmplify) return;

    //tooltips
    const setTranslations = async () => {
      const engTranslations = {};

      while (token.tooltips !== null) {
        amplifyData.tooltips.forEach((translation) => {
          engTranslations[translation.handle] = translation;
        });
      }

      for (let key in engTranslations) {
        if (engTranslations[key]._deleted) {
          delete engTranslations[key];
        }
      }

      setMessages(engTranslations);
    };

    //vehicles
    const vehiclesData = [];

    function findVehicleIndexById(id) {
      let foundIndex = -1;
      Object.keys(vehiclesData).forEach((vehicle, index) => {
        if (vehiclesData[index].id === id) {
          foundIndex = index;
        }
      });
      return foundIndex;
    }

    const apiGetVehicles = async () => {
      amplifyData.vehicles.forEach((vehicle) => {
        const id = vehicle.displayName.replace(/\s+/g, "_");
        let index = findVehicleIndexById(id);

        if (index === -1) {
          vehiclesData.push({
            id: id,
            label: vehicle.displayName,
            trims: [],
          });
          index = vehiclesData.length - 1;
        }

        vehiclesData[index].trims.push({
          handle: vehicle.handle,
          name: vehicle.trim,
          displayName: vehicle.label,
          msrp: vehicle.msrp,
          maintenance: vehicle.maintenance,
        });
      });

      setVehicles(vehiclesData);
    };

    apiGetVehicles();
    setTranslations();
  }, [amplifyData, token, isLoadingAmplify, setVehicles]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (vehicleData && messages) {
      const currentEv = vehicleData.vehicle.handle;
      const selectedEvTooltips = messages[currentEv];
      if (selectedEvTooltips) {
        dispatch(updatetooltips(selectedEvTooltips));
      } else {
        dispatch(updatetooltips(defaultTooltips));
      }
    } else {
      dispatch(updatetooltips(defaultTooltips));
    }
  }, [messages, vehicleData, dispatch]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className="App">
      <Box sx={{ width: "100%" }}>
        {showTabName === "true" || showTabName === undefined ? (
          <Box>
            <Tabs
              centered
              style={{ marginBottom: "-6px" }}
              value={value}
              onChange={handleChange}
              aria-label="hyundai ev widget tabs"
              TabIndicatorProps={{
                style: { transition: "none" },
              }}
            >
              <Tab
                style={{
                  marginBottom: "12px",
                  fontSize: "16px",
                  lineHeight: "16px",
                }}
                sx={{ fontSize: "0.875rem", fontWeight: 700, color: "#666666" }}
                label={
                  tabName === "costofownership"
                    ? "Cost Of Ownership"
                    : tabName === "incentives"
                    ? "Incentives"
                    : "Emissions"
                }
                disableRipple={true}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        ) : null}
        {isError ? (
          <>{error}</>
        ) : isLoading ? (
          <Typography>Loading...</Typography>
        ) : vehicleData && locationData ? (
          <>
            {tabName.toLowerCase() === "incentives" ? (
              <Incentives
                vehicle={vehicleData.vehicle}
                activeTrim={activeTrim}
                vehicleIncentives={vehicleData.vehicle.incentives}
                state={locationData.location.region}
                dealerId={validDealerId}
                showZipCode={showZipCode}
                showModelCard={showModelCard}
              />
            ) : null}

            {tabName.toLowerCase() === "costofownership" ? (
              <CostOfOwnership
                vehicle={vehicleData.vehicle}
                activeTrim={activeTrim}
                showModelCard={showModelCard}
                showZipCode={showZipCode}
              />
            ) : null}

            {tabName.toLowerCase() === "emissions" ? (
              <Emissions
                vehicle={vehicleData.vehicle}
                location={locationData.location}
                activeTrim={activeTrim}
                showModelCard={showModelCard}
                showZipCode={showZipCode}
              />
            ) : null}
          </>
        ) : null}
        {showBranding === "true" || showBranding === undefined ? (
          <Footer />
        ) : null}
      </Box>
    </div>
  );
}

export default App;
