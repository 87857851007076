import GoogleAnalytics from "react-ga4";

const trackers = [];

// Avoiding having trackers populate as a side effect of importing the module.
const hydrateTrackers = () => {
  if(trackers.length) {

    // Already hydrated, go home.
    return;
  }

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "production":
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_OPTIONS_NAME,
          storage: 'none',
          
        }
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_OPTIONS_NAME,
          storage: 'none',
                 
        }
      });
      break;
    case "staging":
      trackers.push({
        trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_STAGING_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_ZAPPYRIDE_STAGING_TRACKING_OPTIONS_NAME,
          storage: 'none',
          
        }
      });
      trackers.push({
        trackingId: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_ID,
        gaOptions: {
          name: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_OPTIONS_NAME,
          storage: 'none',
          
        }
      });
      break;
    default:
  }
}

if(['staging', 'production'].indexOf(process.env.REACT_APP_ENVIRONMENT) > -1) {
  hydrateTrackers();
}

const GaTracker = {
  initialize: () => {
    if (trackers.length) {
      GoogleAnalytics.initialize(trackers);
    }
  },

  getPageName: (page) => {
    const lastCharIndex = page.length - 1;

    return ((page.length > 1) && (page.charAt(lastCharIndex) === '/' )) 
    ? page.substring(0, lastCharIndex)
    : page;
  },

  // For testing purposes only.
  doHydrate: () => {
    hydrateTrackers();
    GaTracker.initialize();
  },
  trackPage: (page, options = {}) => {

    page = GaTracker.getPageName(page);

    if (trackers.length) {
      trackers.forEach(tracker => {
        GoogleAnalytics.set(
          {
            page,
            ...options
          },
          [tracker.gaOptions.name]
        );
        GoogleAnalytics.pageview(page, [tracker.gaOptions.name]); 
      });
    }
  },
  trackEvent: (event = {}, options = {}) => {
    // These fields are required by Google Analytics for events
    if (!event.category || !event.action) {
      return;
    }
    trackers.forEach(tracker => {
      if (options.constructor === Object && Object.keys(options).length > 0) {
        GoogleAnalytics.set(
          {
            ...options
          },
          [tracker.gaOptions.name]
        );
      }
/* Set a custom dimension */
      GoogleAnalytics.event(event, [tracker.gaOptions.name]);
    });
  }
};

export default GaTracker;
