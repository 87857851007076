import { useEffect, useState } from "react";
import {
  getZipcode,
  getActiveTrim,
  getDealerId,
  getTabName,
} from "../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetVehicleQuery,
  useGetLocationQuery,
} from "../redux/services/unifiedAPIQuery";
import {
  updateZipcode,
  updateActiveVehicle,
  updateTrims,
  updateActiveTrim,
  updateElectricityPrice,
  updateFuelPrice,
  updateDealerId,
  updateTabName,
} from "../redux/Slices/userSlice";
import { FormatAsTwoDecimals } from "./Format";
import {
  DEFAULT_VEHICLE,
  DEFAULT_VEHICLE_TRIM,
  VEHICLE_DATA,
} from "../data/vehicles/VehicleData";
import dealers from "../data/dealers/dealersData";
import { updateVehicles } from "../redux/Slices/vehiclesSlice";

const queryString = require("query-string");

export default function useGetData() {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    activeTrim: null,
    validDealerId: null,
    vehicleData: null,
    locationData: null,
  });
  const {
    dealerId: queryDealerId,
    zipcode: queryZipCode,
    tabName: queryTabName,
    trims: queryVehicleTrim,
    showBranding,
    showTabName,
    showZipCode,
    showModelCard,
  } = queryString.parse(window.location.search);
  const [skip, setSkip] = useState(true);

  const [vehicles, setVehicles] = useState(VEHICLE_DATA);
  const activeTrim = useSelector(getActiveTrim);
  const zipcode = useSelector(getZipcode);
  const dealerId = useSelector(getDealerId);
  const tabName = useSelector(getTabName);
  const validDealerId = dealerId || queryDealerId || 999999;
  const validZipcode = zipcode || queryZipCode || 92708;
  const validTabName = tabName || queryTabName || "CostOfOwnership";

  let validVehicleId;
  try {
    const parsedQuery = JSON.parse(queryVehicleTrim);
    validVehicleId = parsedQuery.handle || DEFAULT_VEHICLE;
  } catch (error) {
    validVehicleId = DEFAULT_VEHICLE;
  }

  let validVehicleTrim;
  try {
    const parsedQuery = JSON.parse(queryVehicleTrim);
    validVehicleTrim = parsedQuery.name || DEFAULT_VEHICLE_TRIM;
  } catch (error) {
    validVehicleTrim = DEFAULT_VEHICLE_TRIM;
  }

  const {
    status: vehicleStatus,
    data: vehicleData,
    error: vehicleError,
    isLoading: vehicleLoading,
  } = useGetVehicleQuery(
    {
      handle: activeTrim,
      postcode: validZipcode,
      dealerId: validDealerId,
    },
    { skip }
  );

  const {
    status: locationStatus,
    data: locationData,
    error: locationError,
    isLoading: locationLoading,
  } = useGetLocationQuery({
    postcode: validZipcode,
    dealerId: validDealerId,
  });

  useEffect(() => {
    if (vehicleError || locationError) {
      dispatch(updateZipcode(90210));
    }
  }, [vehicleError, locationError, dispatch]);

  useEffect(() => {
    if (validDealerId) {
      dispatch(updateDealerId(validDealerId));
      window.dealerId = validDealerId;
      let result = dealers.find((dealer) => dealer.dealerId === validDealerId);
      result !== undefined
        ? (window.dealerName = result.dealerName)
        : (window.dealerName = "unknown");
    }
    if (validTabName) {
      dispatch(updateTabName(validTabName.toLowerCase()));
    }
    if (validZipcode) {
      dispatch(updateZipcode(validZipcode));
    }

    let vehicle = VEHICLE_DATA.filter((vehicle) => {
      return vehicle.id === validVehicleId;
    });

    let trims = vehicle[0].trims;
    let activeTrim = trims.filter((trim) => {
      return trim.name === validVehicleTrim;
    });

    if (trims) {
      dispatch(updateActiveVehicle(vehicle[0]));
      dispatch(updateTrims(trims));
      dispatch(updateActiveTrim(activeTrim[0]));
      setSkip(false);
    }
  }, [
    dispatch,
    setSkip,
    validDealerId,
    validZipcode,
    validTabName,
    validVehicleId,
    validVehicleTrim,
  ]);

  useEffect(() => {
       if (vehicles) {
         let trims = vehicles[0].trims;
         dispatch(updateVehicles(vehicles));
         dispatch(updateTrims(trims));
         dispatch(updateActiveTrim(trims[0]));
         setSkip(false);
       }
     }, [vehicles, zipcode, setSkip, dispatch]);

  useEffect(() => {
    if (locationData) {
      const electricityPrice = FormatAsTwoDecimals(
        locationData.location.regional_fuel_cost[0].electricity / 100
      );
      const fuelPrice = FormatAsTwoDecimals(
        locationData.location.regional_fuel_cost[0].gasoline
      );
      dispatch(updateElectricityPrice(electricityPrice));
      dispatch(updateFuelPrice(fuelPrice));
    }
  }, [locationData, dispatch]);

  useEffect(() => {
    if (vehicleStatus !== "fulfilled" || locationStatus !== "fulfilled") return;
    setData({ activeTrim, validDealerId, vehicleData, locationData });
  }, [
    activeTrim,
    validDealerId,
    vehicleStatus,
    vehicleData,
    locationStatus,
    locationData,
  ]);

  return {
    isLoading: vehicleLoading || locationLoading,
    isError: Boolean(vehicleError) || Boolean(locationError),
    error: vehicleError || locationError,
    setVehicles,
    tabName,
    showBranding,
    showTabName,
    showZipCode,
    showModelCard,
    ...data,
  };
}
